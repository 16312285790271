/* MSDOS 6.22 CSS Theme */
:root {
    --charH:      19px;
    --charHneg:  -19px;
    --charW1:      8px;
    --charW2:     16px;
    --charW2neg: -16px;
    --charW3:     24px;
    --charW3neg: -24px;

    --clickable:  #ff0;
    --active:     #800;
    --button:     #66d;
    --focus:      #00f;
    --background: #008;
    --shadow:     #005;
    --neutral4:   #fff;
    --neutral3:   #ccc;
    --neutral2:   #aaa;
    --neutral1:   #777;
    --neutral0:   #000;
}

@font-face {
    font-family: VGA_SquarePx;
    /*src: url(fonts/pxplus_vga_squarepx.ttf) format('truetype');*/
    src: url(fonts/pxplus_chipplayer.ttf) format('truetype');
    font-weight: 400;
    font-style: normal
}

.grid { /* utility class for testing the character grid */
    background-image:
        repeating-linear-gradient(
            to right, #fff1 0px, #fff1 1px, #fff0 1px, #fff0 var(--charW1)),
        repeating-linear-gradient(
            to bottom, #fff2 0px, #fff2 1px, #fff0 1px, #fff0 var(--charH));
}

.App * {
    font-size: 24px !important;
    font-family: VGA_SquarePx, monospace !important;
    -webkit-font-smoothing: antialiased; /* supports Japanese characters better than 'none' */
    -moz-osx-font-smoothing: grayscale; /* Firefox */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    line-height: var(--charH);
    text-decoration: none;
}

*::selection {
    background: rgba(170, 170, 170, 0.99);
    color: #000088;
}

*:focus {
    outline: none;
    background-color: var(--focus);
}

/* Basic elements */

body {
    background: var(--background);
    overflow: hidden;
    color: var(--neutral2);
}

/* Margins in one direction (bottom) */
h1, h2, h3, h4, h5, h6 {
    color: var(--neutral4);
    margin: 0 0 var(--charH) 0;
    padding: 0;
    font-weight: normal;
}

p { margin: 0 0 var(--charH) 0; }

a {
    text-decoration: none;
    color: var(--clickable);
    cursor: pointer;
}

a:focus   { background: var(--focus); }
a:active  { background: var(--active); }
a:visited { color: var(--clickable); }

/* Form elements */

select, button {
    border: none;
    background: none;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 0 var(--charW1);
    color: var(--clickable);
}
select {
    background-color: var(--button);
    background-image: /* A little down-arrow */
            linear-gradient(60deg, transparent 50%, var(--clickable) 50%),
            linear-gradient(120deg, var(--clickable) 50%, transparent 50%);
    background-position: calc(100% - var(--charW1)*1.5) 5px, calc(100% - var(--charW1)) 5px;
    background-size: calc(var(--charW1)*0.5) 12px, calc(var(--charW1)*0.5) 12px;
    background-repeat: no-repeat;
    padding-right: var(--charW2);
    max-width: 240px;
}
select option                { font-size: 14px; }

button          { background: var(--button); padding: 0; margin: 0; }
button:focus    { background: var(--focus); }
button:active   { background: var(--active); }
button:disabled { background: var(--neutral1); color: var(--neutral3); }
#buttonsAndTimeline {
    display: flex;
    flex-direction: row;
}
.box-button        { 
    height: 25px;
    margin: 6px;
    align-self: center;
    padding: 0 var(--charW2); box-shadow: var(--charW1) var(--charW1) var(--shadow); white-space: pre; }
.box-button:active { box-shadow: none; }

input[type=text] {
    border: 0;
    border-radius: 0;
    background-color: var(--neutral2);
    color: var(--background);
    padding: 0 var(--charW1);
    width: 256px;
    height: var(--charH); /* Firefox */
}
input[type=text]::selection                 { background-color: #FFFFFF; }
input[type=text]::-webkit-input-placeholder { color: var(--neutral1) }

input[type=checkbox]:focus,
input[type=radio]:focus,
input[type=range]:focus { background: var(--focus); }

label.inline { display: inline-block; margin-right: var(--charW1);   }
label.inline:last-child { margin-right: 0; }
input[type=checkbox],
input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    width: 32px;
    margin: 0;
    color: var(--clickable);
}

input[type=checkbox]:before         { content: '[ ]'; }
input[type=checkbox]:checked:before { content: '[×]'; }

input[type=radio]:before         { content: '( )'; }
input[type=radio]:checked:before { content: '(●)'; }

input[type=range] {
    margin: 0;
    -webkit-appearance: none;
    background: transparent;
    vertical-align: bottom;
    height: var(--charH); /* Firefox */
    width: 128px; /* Firefox */
}

input[type=range]::-webkit-slider-runnable-track {
    height: 3px;
    border: 1px solid var(--clickable);
    margin: var(--charW1) 0;
}
input[type=range]::-moz-range-track { /* Firefox */
    height: 3px;
    border: 1px solid var(--clickable);
    margin: var(--charW1) 0;
    box-sizing: border-box;
}

input[type=range]:disabled::-webkit-slider-runnable-track { border-color: var(--neutral2); }
input[type=range]:disabled::-moz-range-track { border-color: var(--neutral2); } /* Firefox */
input[type=range]:disabled::-webkit-slider-thumb { background: var(--neutral2); }
input[type=range]:disabled::-moz-range-thumb { background: var(--neutral2); } /* Firefox */
input[type=range]:active::-webkit-slider-thumb { background: var(--clickable); }
input[type=range]:active::-moz-range-thumb { background: var(--clickable); } /* Firefox */

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: var(--clickable);
    border: 0;
    height: var(--charH);
    margin-top: -9px;
    width: var(--charW1);
}
input[type=range]::-moz-range-thumb { /* Firefox */
    -moz-appearance: none;
    background: var(--clickable);
    border: 0;
    border-radius: 0;
    height: var(--charH);
    margin-top: -9px;
    width: var(--charW1);
}
/* Scrollbars */

body { /* Firefox */
    scrollbar-color: var(--clickable) var(--button);
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    height: var(--charW1);
    width: var(--charW1);
    background: var(--button);
}

::-webkit-scrollbar-thumb {
    background: var(--clickable);
    -webkit-border-radius: 0;
}

/* Component overrides */

body,#root {
    margin: 0;
}

.App {
    padding: 0 var(--charW2);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-variant-numeric: tabular-nums;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.AppHeader {
    padding: var(--charH) var(--charW2);
    margin: 0 var(--charW2neg);
    flex-grow: 0;
}

.AppHeader-subtitle {
    margin: var(--charH) 0 0 0;
}

.AppHeader-title, .AppHeader-title:visited, .AppHeader-title:link {
    color: var(--neutral4);
}

.AppHeader-byline {
    float: right;
}

.App-main {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    margin: 0 var(--charW2neg);
    margin-top: -1px; /* shrink by 1px for top border of tabs */
}

.App-main-inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 1px; /* prevents content width priority */
    margin-right: -1px; /* hide the right border of the content area */
}

.App-main-content-and-settings {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-grow: 1;
}

.App-main-content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: var(--charH) var(--charW2);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-right: 1px solid var(--clickable);
    container-type: inline-size;
}
.App-main-content-area:focus { /* I have no idea why this became focusable */
    background-color: initial;
}

.App-main-content-area.settings {
    width: 360px;
    min-width: 360px;
    flex-grow: 0;
}

.AppFooter {
    border: 1px solid var(--clickable);
    margin: 0 var(--charW2neg);
    flex-shrink: 0;
/*    height: 210px;*/
    display: flex;
}

.AppFooter-art {
    width: 210px;
    height: 210px;
    background-color: var(--neutral0);
    object-fit: scale-down;
    image-rendering: unset;
    border-left: 1px solid var(--clickable);
    flex-shrink: 0;
}
@media screen and (max-width: 600px) {
    .AppFooter-art {
        display: none;
    }
}

.AppFooter-main {
    flex-grow: 1;
    overflow: auto;
}

/*
 Required due to strange flexbox/padding behavior in Safari.
 https://stackoverflow.com/questions/42802717
*/
.AppFooter-main-inner {
    padding: 0;
}

marquee {
vertical-align: bottom;
}

.AppFooter-settings {
    border-left: 1px solid var(--clickable);
    padding: var(--charH) var(--charW2);
    max-width: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.App-voice-label {
    width: 112px;
    white-space: nowrap;
    overflow-x: hidden;
}

.AppFooter-more-buttons {
    float: right;
}
@media screen and (max-width: 600px) {
    .AppFooter-more-buttons { display: none; }
}

.Slider {
    height: var(--charH);
    padding: 0;
    cursor: pointer;
    position: relative;
    width: calc(100% - var(--charW1));
    box-sizing: border-box;
    border: 0;
}

.Slider-rail {
    width: calc(100% + var(--charW1));
    height: 3px;
    margin-top: -2px;
    background: none;
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    border: 1px solid var(--clickable);
}

.Slider-knob {
    height: var(--charH);
    width: var(--charW1);
    margin: 0;
    border-radius: 0;
    background-color: var(--clickable);
    position: absolute;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.Browse-topRow { display: flex; justify-content: space-between; margin-bottom: var(--charH); }

.BrowseList-row { display: flex; }
.BrowseList-row:has(a:hover) { color: var(--neutral4); }
.BrowseList-colName { overflow-x: hidden; white-space: nowrap; text-overflow: ellipsis; flex-grow: 1; }
.BrowseList-colDir, .BrowseList-colSize { flex-shrink : 0; margin-left: calc(var(--charW1) * 2); }
.BrowseList-colCount { flex-shrink : 0; margin-left: var(--charW1); width: calc(var(--charW1) * 5); text-align: right; }
.BrowseList-colMtime { text-align: right; text-transform: uppercase; white-space: nowrap; min-width: calc(var(--charW1) * 12); }
.BrowseList-colSize { text-align: right; text-transform: uppercase; white-space: nowrap; min-width: calc(var(--charW1) * 10); }

@media screen and (max-width: 800px) {
    .BrowseList-colMtime { display: none }
}
@container (max-width: 800px) {
    .BrowseList-colMtime { display: none }
}

.DirectoryLink-dim { color: var(--neutral2); }

.PlayerParams h3 { margin-top: 0; }
.PlayerParams-param { display: flex; flex-wrap: wrap; }
.PlayerParams-param>input { margin-right: var(--charW1); }
.PlayerParams-group-title { width: 100%; }
.PlayerParams-voiceList {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--charW1) * -1);
    max-width: calc(var(--charW1) * 60);
}
.PlayerParams-label { flex-shrink: 0; width: calc(var(--charW1) * 14); }
.PlayerParams-group { margin-bottom: var(--charH); }

.SongDetails {}
.SongDetails-title {color: var(--neutral4);}
.SongDetails-subtitle, .SongDetails-subtitle, .SongDetails-filepath {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.SongDetails-filepath a {color: var(--neutral2);}

.Search-input { width: calc(var(--charW1) * 20); }
.Search-clearButton { white-space: pre; }
.Search-clearButton::before { content: ' ×'; }
.Search-clearButton::after  { content: ' '; }
.Search-results { margin-top: var(--charH); }
.Search-resultsLabel { white-space: nowrap; }

.Song-now-playing .Favorite-button { color: var(--background); }
.Favorite-button:active { color: var(--clickable); }
.Favorite-button.isFavorite { color: var(--clickable); }
.Favorite-button {
    cursor: pointer;
    color: var(--button);
    background: initial;
    padding: 0 var(--charW1);
}

/*.Song-now-playing .Trash-button { background: var(--button); }*/
.Trash-button {
    cursor: pointer;
    background: initial;
}

.Visualizer { position: relative; display: flex; flex-direction: column; align-items: flex-end }
.Visualizer-overlay { position: absolute; top: calc(4 * var(--charH)); image-rendering: pixelated; }
.Visualizer-options { padding: var(--charH) var(--charW2) 0; text-align: right; }
.Visualizer-toggle { position: fixed; top: var(--charH); right: var(--charW2); }
.Visualizer-analyzer { display: block; image-rendering: pixelated; }
.Visualizer-spectrogram { display: block; flex-grow: 1; flex-shrink: 0; min-height: 800px; image-rendering: pixelated; }
.Visualizer-analyzer, .Visualizer-spectrogram {
    margin-left: 1px; /* Don't cover the right border of App-main-inner */
}

.TimeSlider { flex-grow: 1; margin: 5px; }
.TimeSlider-labels, .VolumeSlider-labels { display: flex; flex-direction: row; justify-content: space-between; }

.VolumeSlider { flex-shrink: 0; margin-top: var(--charH); margin-bottom: var(--charH); margin-left: var(--charW2); }
@media screen and (max-width: 600px) {
    .VolumeSlider { display: none; }
}

.Song-now-playing {
    background-color: var(--button);
    color: var(--neutral3);
}

td { padding: 0 }
img { image-rendering: pixelated; }

.inline-icon { vertical-align: bottom; }
.dim-icon { filter: grayscale(100%) brightness(0.75); }

.tab-container {
    border-bottom: 1px solid var(--clickable);
    padding: 0 var(--charW2);
    display: flex;
}

.tab {
    display: inline-block;
    padding: 0 15px 0 15px;
    background: transparent;
    border: 1px solid var(--clickable);
    border-bottom: none;
    margin: 0 8px 0 0;
    border-radius: 3px 3px 0 0;
    box-sizing: border-box;
    height: var(--charH);
}

.tab-selected {
    border-bottom: 1px solid var(--background);
    background: var(--background);
    margin-bottom: -1px;
    height: calc(var(--charH) + 1px);
}

.toast-box-outer {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    top: 0;
    left: 0;
    transition: all 150ms steps(4);
    padding: 0 var(--charW2);
    box-sizing: border-box;
}
.toast-box {
    padding: var(--charH) var(--charW2);
    box-shadow: var(--charW1) var(--charW1) var(--shadow);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
    overflow: hidden;
    max-height: 100%;
    pointer-events: all;
    width: 100%;
    height: calc(4 * var(--charH));
}
.toast-box-outer[hidden] {
    top: calc(-4 * var(--charH));
    visibility: hidden;
}
.toast-box .message {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-height: 100%;
    overflow: hidden;
    padding-right: var(--charW1);
    text-overflow: ellipsis;
    white-space: initial;
}
.toast-error {
    background-color: var(--active);
    color: var(--neutral4);
}
.toast-info {
    background-color: var(--button);
    color: var(--neutral3);
}

/* message-box is used by Info box and DropMessage component. */
.message-box-outer {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    pointer-events: none;
    top: 0;
    left: 0;
    padding: var(--charH) var(--charW2);
    box-sizing: border-box;
}

.message-box-outer[hidden] {
    visibility: hidden;
}

.message-box {
    background-color: var(--button);
    padding: var(--charH) var(--charW2);
    box-shadow: var(--charW1) var(--charW1) var(--shadow);
    box-sizing: border-box;
    display: flex;
    transition: all 250ms steps(5);
    visibility: visible;
    overflow: hidden;
    max-height: 100%;
    pointer-events: all;
    flex-direction: column;
    width: calc(90 * var(--charW1));
    height: calc(40 * var(--charH));
}

.message-box[hidden] {
    width: 0;
    height: 0;
    visibility: hidden;
}

.message-box[hidden] .message-box-inner {
    overflow: hidden;
}

.message-box-inner {
    color: var(--clickable);
    border: 1px solid var(--clickable);
    padding: var(--charH) var(--charW2);
    overflow: auto;
}

.message-box-footer {
    display: flex;
    flex-direction: row-reverse;
    align-content: end;
    justify-content: space-between;
    padding-top: var(--charH);
}

.drop-message {
    height: calc(9 * var(--charH) + 1px);
    width: calc(60 * var(--charW1));
    text-align: center;
}

.drop-message .message-box-inner {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.message-box-button        { background: var(--background); padding: 0 var(--charW2); box-shadow: none; }
